import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Badge, Button, Card, Form, FormGroup, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styles from './liste-categorie-evenement.module.css';
import { creerCleUnique } from '../../utilitaires/listUtil';
import { obtenir_toutes_les_categories_evenement, supprimer_categorie_evenement } from '../../services/categorieEvenementService';
import { useNavigate } from 'react-router-dom';

function genererListeEvenement(state, setState, width) {

    if(width <= 925)
    {
      return (
        state.listeCategories &&
        state.listeCategories
        .filter((categorie) => {
          if(state.texteRecherche)
          {
            if(categorie.nom && categorie.nom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
            if(categorie.slug && categorie.slug.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
            if(categorie.description && categorie.description.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
            return false;
          }
          return true;
        })
        .slice(state.indexCategorie, state.indexCategorie + 10)
        .map((categorie) => {
          return (
            <Card className={styles.conteneur_liste_evenement}>
              <Card.Body>
                <Card.Title className={styles.titre_carte_categorie_evenement}>{categorie.nom}</Card.Title>
                <Card.Text>{categorie.description ?? "N/D"}</Card.Text>
                <LinkContainer className={styles.hf_bouton_liste} to={"/voir-tous-les-evenement/categorie/" + categorie.slug}><Badge bg="primary">Voir la catégorie d'événement</Badge></LinkContainer>
                {
                  categorie.slug !== "non-categorise" &&
                  <>
                    <LinkContainer className={styles.hf_bouton_liste} to={"/modifier-categorie-evenement/" + categorie.slug}><Badge bg="primary">Modifier la catégorie</Badge></LinkContainer>
                    <Badge 
                      className={styles.hf_bouton_liste} 
                      onClick={
                        (event) => { 
                          event.preventDefault();
                          supprimer_categorie_evenement(state, setState, categorie.id) 
                        }
                      } 
                      bg="primary"
                    >Supprimer la catégorie</Badge>
                  </>
                }
              </Card.Body>
            </Card>
          )
        })
      )
    }

    return (
      <Table className={styles.conteneur_liste_evenement} variant="dark" striped bordered hover size="sm" responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Slug</th>
            <th>Nom de la catégorie</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            state.listeCategories &&
            state.listeCategories
            .filter((categorie) => {
              if(state.texteRecherche)
              {
                if(categorie.nom && categorie.nom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
                if(categorie.slug && categorie.slug.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
                if(categorie.description && categorie.description.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
                return false;
              }
              return true;
            })
            .slice(state.indexCategorie, state.indexCategorie + 10)
            .map((categorie) => {
              return (
                <tr key={creerCleUnique()}>
                  <td>{categorie.id}</td>
                  <td>{categorie.slug}</td>
                  <td>{categorie.nom}</td>
                  <td>{categorie.description ?? "N/D"}</td>
                  <td>
                    <LinkContainer className={styles.hf_bouton_liste} to={"/voir-tous-les-evenement/categorie/" + categorie.slug}><Badge bg="primary">Voir la catégorie d'événement</Badge></LinkContainer>
                    {
                      categorie.slug !== "non-categorise" &&
                      <>
                        <LinkContainer className={styles.hf_bouton_liste} to={"/modifier-categorie-evenement/" + categorie.slug}><Badge bg="primary">Modifier la catégorie</Badge></LinkContainer>
                        <Badge 
                          className={styles.hf_bouton_liste} 
                          onClick={
                            (event) => { 
                              event.preventDefault();
                              supprimer_categorie_evenement(state, setState, categorie.id)
                            }
                          } 
                          bg="primary"
                        >Supprimer la catégorie</Badge>
                      </>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    );
  }

export default function ListeCategorieEvenement() {
    const [state, setState] = useState({
      listeCategories: null,
      texteRecherche: "",
      indexCategorie: 0
    });
    const width = window.innerWidth;
    const navigate = useNavigate();

    useEffect(() => {
        !state.listeCategories && obtenir_toutes_les_categories_evenement(state, setState); 
    }, [state])
    
    return (
      <>
        <Row className='text-center'>
          <Col lg="4"></Col>
          <Col lg="4">
            <Button
              onClick={() => {
                navigate("/ajouter-categorie-evenement")
              }}
              className={styles.bouton_ajout_categorie_evenement}
            >Ajouter une catégorie d'événement</Button>
          </Col>
          <Col lg="4"></Col>
        </Row>
        <Row className={'text-center ' + styles.conteneur_bouton_pagination}>
              <Col lg="1"></Col>
              <Col lg="3" xs="12">
                <Button
                  disabled={state.listeCategories && state.indexCategorie <= 0}
                  onClick={() => {
                    if(state.listeCategories && state.indexCategorie > 0){
                      setState({
                        ...state,
                        indexCategorie: state.indexCategorie - 10
                      })
                    }
                  }}
                >Précédent</Button>
              </Col>
              <Col lg="4" xs="12" className="text-center">
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <FormGroup>
                      <input 
                        className='form-control'
                        type='text'
                        placeholder="Rechercher une catégorie d'événement"
                        value={state.texteRecherche}
                        onChange={(event) => {
                          setState({
                            ...state,
                            texteRecherche: event.target.value
                          })
                        }}
                      />
                    </FormGroup>
                  </Form>
              </Col>
              <Col lg="3" xs="12">
                <Button
                  disabled={state.listeCategories && state.indexCategorie > state.listeCategories.length - 10}
                  onClick={() => {
                    if(state.listeCategories && state.indexCategorie < state.listeCategories.length){
                      setState({
                        ...state,
                        indexCategorie: state.indexCategorie + 10
                      })
                    }
                  }}
                >Suivant</Button>
              </Col>
              <Col lg="1" xs="2"></Col>
          </Row>
          <Row>
              <Col xs="1"></Col>
              <Col xs="10" className="text-center">
                  {
                      state.listeCategories && genererListeEvenement(state, setState, width)
                  }
                  {
                      !state.listeCategories && <Alert variant='warning' className={styles.alerte_aucune_categorie}>Aucune catégorie d'événement trouvé !</Alert>
                  }
              </Col>
              <Col xs="1"></Col>
          </Row>
        </>
    );
  }